
import ISelectOptions from "./ISelectOptions";

//1 - Código PPR, 2 - Protocolo PPR, 3 - Consulta rendimento
export type ITipoMensagem = 1 | 2 | 3;
export type ITipoSolicitante = 1 | 2;
export type ITipoChavePix = 1 | 2 | 3 | 4;
export type ConfirmacaoContato = "E" | "T";
export type ITipoConta = 1 | 2;

export enum IOrigemSms {
    PPR_INATIVO = 1,
    INFORME_DE_RENDIMENTOS = 2,
}

export const tiposChavePix: ISelectOptions[] = [{ label: "CPF ou CNPJ", value: 1 }, { label: "Celular", value: 2 }, { label: "Email", value: 3 }, { label: "Chave Aleatória", value: 4 }];

export function getTipoConta(tipo: ITipoConta) {
    switch (tipo) {
        case 1: return "Conta Corrente";
        case 2: return "Conta Poupança";
        default: return "---";
    }
}

export function getTipoSolicitante(tipo: ITipoSolicitante) {
    switch (tipo) {
        case 1: return "ex-colaborador";
        case 2: return "via representante";
        default: return "---";
    }
}

export function getTipoChavepix(tipo: ITipoChavePix) {
    switch (tipo) {
        case 1: return "CPF/CNPJ";
        case 2: return "Telefone";
        case 3: return "E-mail";
        case 4: return "Chave Aleatória";
        default: return "---";
    }
}
export const Options_ChavePix: ISelectOptions[] = [
    { value: 1, label: getTipoChavepix(1) },
    { value: 2, label: getTipoChavepix(2) },
    { value: 3, label: getTipoChavepix(3) },
    { value: 4, label: getTipoChavepix(4) },
]

export interface ISituacao {
    Id: number;
    Nome: string;
    Ordem: number;
    Cor: string;
    Descricao: string;
}

export interface IPPRInativoRequisicao {
    Id: number;
    Protocolo: number;
    NomeColaborador: string;
    CpfColaborador: string;
    DataSolicitacao: string;
    TipoSolicitante: ITipoSolicitante;
    CodBanco: number;
    Banco: string;
    Agencia: string;
    DigitoAgencia: string;
    TipoChavePix: ITipoChavePix | null;
    ChavePix: string | null;
    Situacao: number;
    SolicitanteCPF: string;
    SolicitanteNome: string;
    SolicitanteEmail: string;
    SolicitanteTelefone: string;
    TipoConfirmacao: ConfirmacaoContato;
    TipoConta: ITipoConta;
    NumeroConta: number;
    DigitoConta: string;
    Operacao: number;
    TipoContato: ConfirmacaoContato;
    RepresentadoCPF: string | null;
    RepresentadoNome: string | null;
    RepresentadoEmail: string | null;
    RepresentadoTelefone: string | null;
    ConjugeCompanheiro: boolean | null;
    HerdeiroDescendente: boolean | null;
    SemRelacao: boolean | null;
    TitularFalecido: boolean | null;
    Etapa: number;
    DataBeneficio: string | null;
    NomeMaeRepresentado: string;
    UltimaUnidadeNegocio: number;
    UltimoCargo: number;
    OpcaoRecebimentoPIX: ITipoChavePix | null;
    ChavePIX: string | null;
}

export interface POSTPPR {
    Id: number;
    TipoSolicitante: number;
    SolicitanteCPF: string;
    SolicitanteNome: string;
    SolicitanteEmail: string;
    SolicitanteTelefone: string;
    TipoConfirmacao: ConfirmacaoContato;
    Banco: number;
    TipoConta: number;
    Agencia: number;
    DigitoAgencia: string;
    NumeroConta: number;
    DigitoConta: string;
    Operacao?: number;
    TipoContato: ConfirmacaoContato;
    RepresentadoCPF?: string;
    RepresentadoNome?: string;
    RepresentadoEmail?: string;
    RepresentadoTelefone?: string;
    ConjugeCompanheiro?: boolean;
    HerdeiroDescendente?: boolean;
    SemRelacao?: boolean;
    TitularFalecido?: boolean;
    NomeMaeRepresentado: string
    UltimaUnidadeNegocio: number;
    UltimoCargo: number;
    OpcaoRecebimentoPIX?: number;
    ChavePIX?: string;
}

export interface IHistoricoAtendimento {
    Id: number;
    Solicitacao: number;
    Mensagem: string;
    DataAlteracao: string;
    UsuarioAlteracao: number;
    NomeUsuario: string;
}

export interface IDadosIdentificacao {
    Cpf: string, 
    Nome: string, 
    Email: string, 
    Telefone: string, 
    NomeMae: string, 
    UnidadeNegocio: ISelectOptions, 
    UltimoCargo: ISelectOptions, 
    TipoConfirmacao: ConfirmacaoContato
}

export interface IDadosRepresentante {
    CpfRepresentante: string, 
    NomeRepresentante: string, 
    EmailRepresentante: string, 
    TelefoneRepresentante: string, 
    ConjugueCompanheiro: boolean, 
    HerdeiroDescendente: boolean, 
    SemRelacao: boolean, 
    TipoConfirmacao: ConfirmacaoContato
}

export interface IDadosBancarios {
    Banco: ISelectOptions, 
    TipoConta: ISelectOptions, 
    Agencia: string, 
    AgenciaDigito: string, 
    NumeroConta: string, 
    ContaDigito: string, 
    Operacao: string, 
    TipoContato: "E" | "T", 
    TipoPix: ISelectOptions | null, 
    ChavePix: string
}