import React, { useCallback, useEffect, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import Select from 'react-select';
import ISelectOptions from "../../interfaces/ISelectOptions"
import api from '../../services/api';
import { formataAgencia, formataChavePix, formataDigito, formataNumeroConta, formataOperacao } from '../../utils/formataCampos';
import { validaAgencia, validaChavePix, validaNumeroConta } from '../../utils/validaCampos';
import { IDadosBancarios, tiposChavePix } from "../../interfaces/IPPRInativoSolicitacao";
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import { IBanco } from '../../interfaces/IBanco';
import verificaTodosParametrosFuncaoSaoVerdadeiros from '../../utils/verificaTodosParametrosFuncaoSaoVerdadeiros';

interface DadosBancariosProps {
    tipoSolicitante: number;
    formulario: IDadosBancarios | null;
    preencheFormulario: (dados: IDadosBancarios) => void;
}

const ContaBancariaForm: React.FC<DadosBancariosProps> = ({ formulario, preencheFormulario, tipoSolicitante }) => {
    const [banco, setBanco] = useState<ISelectOptions | null>(null);
    const [bancos, setBancos] = useState<ISelectOptions[]>([]);
    const [carregandoBancos, setCarregandoBancos] = useState(false);
    const [tipoConta, setTipoConta] = useState<ISelectOptions | null>(null);
    const tiposConta: ISelectOptions[] = [{ label: "Conta Corrente", value: 1 }, { label: "Conta Poupança", value: 2 }];
    const [agencia, setAgencia] = useState("");
    const [agenciaDigito, setAgenciaDigito] = useState("");
    const [numeroConta, setNumeroConta] = useState("");
    const [contaDigito, setContaDigito] = useState("");
    const [operacao, setOperacao] = useState("");
    const [tipoContato, setTipoContato] = useState<"E" | "T">("E");
    const [opcaoRecebimentoPix, setOpcaoRecebimentoPix] = useState<ISelectOptions | null>(null);
    const [chavePix, setChavePix] = useState("");

    const [agenciaIsValid, setAgenciaIsValid] = useState(true);
    const [agenciaDigitoIsValid, setAgenciaDigitoIsValid] = useState(true);
    const [numeroContaIsValid, setNumeroContaIsValid] = useState(true);
    const [contaDigitoIsValid, setContaDigitoIsValid] = useState(true);
    const [operacaoIsValid, setOperacaoIsValid] = useState(true);
    const [chavePixIsValid, setChavePixIsValid] = useState(true);

    const { setCurrentStep, step } = useForm();

    const condicaoOperacao = banco ? banco.RequerOperacao : false;

    const validacao: boolean = podeProsseguir();

    function podeProsseguir(): boolean {
        const bancoPreenchido = banco !== null;
        const tipoContaPreenchido = tipoConta !== null;
        const agenciaPreenchidaEValida = (agencia !== null)
            && (agencia !== "" && agenciaIsValid);
        const digitoAgenciaValido = digitoValido(banco?.possuiDigitoAgencia, agenciaDigito)
        const numeroContaPreenchidoEValido = numeroConta !== "" && numeroContaIsValid;
        const numeroAgenciaPreenchidoEValido = true;
        const pixPreenchidoEValido = (opcaoRecebimentoPix != null && chavePix !== "")
            || opcaoRecebimentoPix == null;
        const bancoAtivo = banco?.ativo === "S";
        const contaDigitoPreenchidoEValido = (contaDigito !== null || contaDigito !== "") && contaDigitoIsValid;

        return verificaTodosParametrosFuncaoSaoVerdadeiros(bancoPreenchido,
            tipoContaPreenchido,
            agenciaPreenchidaEValida,
            digitoAgenciaValido,
            numeroContaPreenchidoEValido,
            contaDigitoPreenchidoEValido,
            numeroAgenciaPreenchidoEValido,
            pixPreenchidoEValido,
            bancoAtivo
        );
    }

    const avancaForm = useCallback(
        () => {
            if (!!banco && !!tipoConta) {
                preencheFormulario({
                    Banco: banco,
                    TipoConta: tipoConta,
                    Agencia: agencia,
                    AgenciaDigito: agenciaDigito,
                    NumeroConta: numeroConta,
                    ContaDigito: contaDigito,
                    Operacao: operacao,
                    TipoContato: tipoContato,
                    TipoPix: opcaoRecebimentoPix,
                    ChavePix: chavePix
                })
                setCurrentStep(step + 1);
            }
        },
        [banco,
            tipoConta,
            agencia,
            agenciaDigito,
            numeroConta,
            contaDigito,
            operacao,
            preencheFormulario,
            tipoContato,
            opcaoRecebimentoPix,
            chavePix,
            setCurrentStep,
            step],
    )

    const voltarForm = useCallback(
        () => {
            setCurrentStep(step - 1);
        },
        [step, setCurrentStep],
    )

    useEffect(() => {
        if (formulario) {
            setBanco(formulario.Banco)
            setTipoConta(formulario.TipoConta)
            setAgencia(formulario.Agencia)
            setAgenciaDigito(formulario.AgenciaDigito)
            setNumeroConta(formulario.NumeroConta)
            setContaDigito(formulario.ContaDigito)
            setOperacao(formulario.Operacao)
            setTipoContato(formulario.TipoContato)
            setOpcaoRecebimentoPix(formulario.TipoPix);
            setChavePix(formulario.ChavePix);
        }
    }, [formulario])

    const fetch = useCallback(() => {
        setCarregandoBancos(true);
        api.get("/api/Banco/GetBancos?somenteAtivo=S")
            .then((res: any) => {
                setBancos(res.data.map((b: IBanco) => ({
                    label: b.Nome,
                    value: b.Id,
                    ativo: b.Ativo,
                    codFebraban: b.CodFebraban,
                    requerOperacao: b.RequerOperacao,
                    possuiDigitoAgencia: b.PossuiDigitoAgencia
                })))
            })
            .finally(() => {
                setCarregandoBancos(false);
            })
    }, [])

    function TrataMudancaBanco(banco: ISelectOptions | null) {
        setBanco(banco);
        setOperacao("");
        setOperacaoIsValid(true);
        if (!banco?.possuiDigitoAgencia) {
            setAgenciaDigito("");
        }
    }

    function ExecuteValidacaoAgencia(agencia: any) {
        let isvalid = validaAgencia(agencia);
        if (banco?.SomenteUmaAgencia === "S" && isvalid) {
            if (banco.Agencia !== agencia) {
                isvalid = false;
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: "O banco selecionado possui apenas uma agência e o número correto é o " + banco.Agencia,
                    severity: "error"
                });
            }
        }

        return isvalid;
    }

    useEffect(() => {
        fetch();
    }, [fetch])

    const validacaoAgencia = !agenciaDigitoIsValid ? Styles.fieldWithError : "";

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`${Styles.botaoVoltarRow}`}>
                    <button className={`btn ${Styles.botaoVoltar}`} onClick={voltarForm}><FaLessThan /></button>
                </div>
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Dados para Recebimento do PPR</h2>
                    <p>Todos os campos marcados com ' * ' são obrigatórios</p>
                    {
                        tipoSolicitante === 1 ?
                            <p className={`${Styles.obs}`}>
                                OBS: Os dados para recebimento deverão ser do ex-colaborador!
                            </p> : ""
                    }
                    <div className="row mt-4 mb-4">
                        <p><strong>Informações da conta bancária (conta corrente ou poupança)</strong></p>
                        <div className="form-group col-md-6 ">
                            <label>Banco *</label>
                            <Select
                                value={banco}
                                onChange={(value) => {
                                    TrataMudancaBanco(value);
                                }}
                                options={bancos}
                                isLoading={carregandoBancos}
                                isClearable
                                placeholder={"Selecione um banco"}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                        </div>
                        <div className="form-group col-md-6 ">
                            <label>Tipo de Conta *</label>
                            <Select
                                value={tipoConta}
                                onChange={(value) => setTipoConta(value)}
                                options={tiposConta}
                                isClearable
                                placeholder={"Selecione o tipo "}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-4 ">
                            <div className="d-flex">

                                <div className="flex-grow-1">

                                    <label>Agência *</label>
                                    <input className={`form-control ${!agenciaIsValid ? Styles.fieldWithError : ""}`}
                                        value={agencia} placeholder="xxxxx" maxLength={5}
                                        onChange={(data) => {
                                            setAgencia(formataAgencia(data.target.value))
                                            setAgenciaIsValid(true);
                                        }}
                                        onBlur={() => setAgenciaIsValid(ExecuteValidacaoAgencia(agencia))}
                                    />
                                    {!agenciaIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Agência inválida
                                        </span> : ""
                                    }
                                </div>
                                <div className={Styles.digito}>

                                    <label>Dígito{banco?.possuiDigitoAgencia ? "*" : ""}</label>
                                    <input className={`form-control ${banco?.PossuiDigitoAgencia ? validacaoAgencia : ""}`}
                                        disabled={!banco?.possuiDigitoAgencia}
                                        value={agenciaDigito} placeholder="" maxLength={1}
                                        onChange={(data) => {
                                            setAgenciaDigito(formataDigito(data.target.value));
                                            setAgenciaDigitoIsValid(true);
                                        }}
                                        onBlur={() => setAgenciaDigitoIsValid(agenciaDigito.length === 1)}
                                    />
                                    {(banco?.possuiDigitoAgencia && !agenciaDigitoIsValid) ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Dígito inválido
                                        </span> : ""
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="form-group col-md-4 ">
                            <div className="d-flex">
                                <div className='flex-grow-1'>

                                    <label>N.º Conta *</label>
                                    <input className={`form-control ${!numeroContaIsValid ? Styles.fieldWithError : ""}`}
                                        value={numeroConta} placeholder="xxxxxxxxxxxx" maxLength={12}
                                        onChange={(data) => {
                                            setNumeroConta(formataNumeroConta(data.target.value));
                                            setNumeroContaIsValid(true);
                                        }}
                                        onBlur={() => setNumeroContaIsValid(validaNumeroConta(numeroConta))}
                                    />
                                    {!numeroContaIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            N.º Conta inválido
                                        </span> : ""
                                    }
                                </div>
                                <div className={Styles.digito}>

                                    <label>Dígito*</label>
                                    <input className={`form-control ${!contaDigitoIsValid ? Styles.fieldWithError : ""}`}
                                        value={contaDigito} placeholder="" maxLength={1}
                                        onChange={(data) => {
                                            setContaDigito(formataDigito(data.target.value));
                                            setContaDigitoIsValid(true);
                                        }}
                                        onBlur={() => setContaDigitoIsValid(contaDigito.length === 1)}
                                    />
                                    {!contaDigitoIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Dígito inválido
                                        </span> : ""
                                    }
                                </div>
                            </div>
                        </div>
                        {condicaoOperacao &&
                            <div className="form-group col-md-3 ">
                                <label>Operação *</label>
                                <input className={`form-control ${!operacaoIsValid ? Styles.fieldWithError : ""}`}
                                    value={operacao} placeholder="xxx" maxLength={3}
                                    onChange={(data) => {
                                        setOperacao(formataOperacao(data.target.value));
                                        setOperacaoIsValid(true);
                                    }}
                                    onBlur={() => setOperacaoIsValid(operacao.length === 3)}
                                />
                                {!operacaoIsValid ?
                                    <span
                                        className={Styles.errorSpan}
                                    >
                                        Operação inválida
                                    </span> : ""
                                }
                            </div>
                        }
                    </div>
                    <div className="row mb-4">
                        <div><strong>Informações PIX</strong></div>
                        <p><small>Informe o seu PIX para que possamos utilizá-lo caso encontrarmos algum problema nas informações da conta bancária</small></p>
                        <div className="form-group col-md-4 ">
                            <label>Tipo PIX</label>
                            <Select
                                value={opcaoRecebimentoPix}
                                onChange={(value) => {
                                    setOpcaoRecebimentoPix(value);
                                    setChavePix("");
                                    setChavePixIsValid(true);
                                }}
                                options={tiposChavePix}
                                isClearable
                                placeholder={"Selecione o tipo"}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                        </div>
                        <div className="form-group col-md-4 ">
                            <label>Chave {!!opcaoRecebimentoPix ? "*" : ""}</label>
                            <input className={`form-control ${!chavePixIsValid ? Styles.fieldWithError : ""}`}
                                value={chavePix} placeholder="Digite sua chave PIX" disabled={!(opcaoRecebimentoPix)}
                                onChange={(data) => {
                                    setChavePix(formataChavePix(data.target.value, opcaoRecebimentoPix?.value));
                                    setChavePixIsValid(true);
                                }}
                                onBlur={() => setChavePixIsValid(validaChavePix(chavePix, opcaoRecebimentoPix?.value))}
                            />
                            {!chavePixIsValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    Chave inválida
                                </span> : ""
                            }
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-12">
                            <label>Escolha um meio de contato em caso de esclarecimento *:</label>
                            <br />
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="email"
                                    checked={tipoContato === "E"} onChange={() => setTipoContato("E")}
                                />
                                <label htmlFor="email">&nbsp;E-mail já informado</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="telefone"
                                    checked={tipoContato === "T"} onChange={() => setTipoContato("T")}
                                />
                                <label htmlFor="telefone">&nbsp;Telefone já informado</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button className={`btn ${Styles.botaoContinuar}`}
                        onClick={avancaForm}
                        disabled={!validacao}>Continuar Solicitação <FaGreaterThan /></button>
                </div>
            </div>
        </>
    )
}

function digitoValido(possuiDigito: boolean | null, digito: string): boolean {
    if (possuiDigito === true && digito === "") {
        return false;
    }
    return true;
}

export default ContaBancariaForm
