import React, { useCallback, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import ISelectOptions from "../../interfaces/ISelectOptions";
import { geraNotificacao } from "../../utils/funcoesNotificacao";
import api from '../../services/api';
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import { ConfirmacaoContato } from '../../interfaces/IPPRInativoSolicitacao';
import Collapse from 'react-bootstrap/Collapse'
import LoadingModal from '../../components/LoadingModal';

interface ConfirmacaoProps {
    formulario: {
        Cpf: string, 
        Nome: string, 
        Email: string, 
        Telefone: string, 
        NomeMae: string, 
        UnidadeNegocio: ISelectOptions, 
        UltimoCargo: ISelectOptions, 
        TipoConfirmacao: ConfirmacaoContato,
        CpfRepresentante: string, 
        NomeRepresentante: string, 
        EmailRepresentante: string, 
        TelefoneRepresentante: string, 
        ConjugueCompanheiro: boolean, 
        HerdeiroDescendente: boolean, 
        SemRelacao: boolean, 
        Banco: ISelectOptions, 
        TipoConta: ISelectOptions, 
        Agencia: string, 
        AgenciaDigito: string, 
        NumeroConta: string, 
        ContaDigito: string, 
        Operacao: string, 
        TipoContato: "E" | "T", 
        TipoPix: ISelectOptions | null, 
        ChavePix: string
    }
    anexos: any[];
}

interface POST {
    TipoSolicitante: number;
    SolicitanteCPF: string;
    SolicitanteNome: string;
    SolicitanteEmail: string;
    SolicitanteTelefone: string;
    TipoConfirmacao: "E" | "T";
    Banco: number;
    TipoConta: number;
    Agencia: number;
    DigitoAgencia: string;
    NumeroConta: number;
    DigitoConta: string;
    Operacao?: number;
    TipoContato: "E" | "T";
    RepresentadoCPF?: string;
    RepresentadoNome?: string;
    RepresentadoEmail?: string;
    RepresentadoTelefone?: string;
    ConjugeCompanheiro?: boolean;
    HerdeiroDescendente?: boolean;
    SemRelacao?: boolean | null;
    TitularFalecido?: boolean;
    NomeMaeRepresentado: string
    UltimaUnidadeNegocio: number;
    UltimoCargo: number;
    OpcaoRecebimentoPIX?: number;
    ChavePIX?: string;
    AnoBase?:number;
}

const Confirmacao: React.FC<ConfirmacaoProps> = ({ formulario, anexos }) => {

    const { setCurrentStep, step } = useForm();

    const [openExColaborador, setOpenExColaborador] = useState(true);
    const [openRepresentante, setOpenRepresentante] = useState(true);
    const [openDadosBancarios, setOpenDadosBancarios] = useState(true);

    const [criandoSolicitacao, setCriandoSolicitacao] = useState(false);

    const PagesContext = usePagesContext();

    const avancaForm = () => {
        setCriandoSolicitacao(true);
        const tipoSolicitante = PagesContext.tipoSolicitante;
        const data: POST = {
            TipoSolicitante: tipoSolicitante,
            SolicitanteCPF: tipoSolicitante === 1 ? formulario.Cpf : formulario.CpfRepresentante || "",
            SolicitanteNome: tipoSolicitante === 1 ? formulario.Nome : formulario.NomeRepresentante || "",
            SolicitanteEmail: tipoSolicitante === 1 ? formulario.Email : formulario.EmailRepresentante || "",
            SolicitanteTelefone: tipoSolicitante === 1 ? formulario.Telefone : formulario.TelefoneRepresentante || "",
            TipoConfirmacao: formulario.TipoConfirmacao,
            Banco: formulario.Banco?.value,
            TipoConta: formulario.TipoConta?.value,
            Agencia: +(formulario.Agencia),
            DigitoAgencia: formulario.AgenciaDigito,
            NumeroConta: +(formulario.NumeroConta),
            DigitoConta: formulario.ContaDigito,
            Operacao: +formulario.Operacao ?? 0,
            TipoContato: formulario.TipoContato,
            RepresentadoCPF: tipoSolicitante === 2 ? formulario.Cpf : formulario.CpfRepresentante || "",
            RepresentadoNome: tipoSolicitante === 2 ? formulario.Nome : formulario.NomeRepresentante || "",
            RepresentadoEmail: tipoSolicitante === 2 ? formulario.Email : formulario.EmailRepresentante || "",
            RepresentadoTelefone: tipoSolicitante === 2 ? formulario.Telefone : formulario.TelefoneRepresentante || "",
            ConjugeCompanheiro: formulario.ConjugueCompanheiro ?? false,
            HerdeiroDescendente: formulario.HerdeiroDescendente ?? false,
            SemRelacao: formulario.SemRelacao ?? false,
            TitularFalecido: false,
            NomeMaeRepresentado: formulario.NomeMae,
            UltimaUnidadeNegocio: formulario.UnidadeNegocio?.value,
            UltimoCargo: formulario.UltimoCargo?.value,
            OpcaoRecebimentoPIX: formulario.TipoPix?.value ?? 0,
            ChavePIX: formulario.ChavePix,
            AnoBase: PagesContext.anoBase
        }

        const data_entries = Object.entries(data);

        const formData = new FormData();

        data_entries.forEach((e) => {
            formData.append(e[0], e[1]);
        })

        if (anexos.length) {
            anexos.forEach((anexo) =>
                formData.append("Anexos", anexo)
            )
        }

        api.post("/api/Solicitacao/SalvarSolicitacao", formData)
            .then((res: any) => {
                setCriandoSolicitacao(false);
                PagesContext.AtualizarProtocolo(res.data.Model);
                PagesContext.changePage(PagesPPR.TelaDeProtocolo);
            })
            .catch((erro: any) => {
                setCriandoSolicitacao(false);
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: JSON.stringify(erro.response.data),
                    severity: "error"
                });
            });
    }

    const voltarForm = useCallback(
        () => {
            setCurrentStep(step - 1);
        },
        [step, setCurrentStep],
    )


    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Confirme os Dados e Finalize a Solicitação</h2>

                    <div className="row mb-4 mt-4">
                        <div className={Styles.dadosBox}>
                            <label className={Styles.dadosLabel}
                                onClick={() => setOpenExColaborador(!openExColaborador)}
                                aria-controls="excolaborador"
                                aria-expanded={openExColaborador}
                            >Dados do Ex-colaborador</label>
                            <Collapse in={openExColaborador} >
                                <div id="excolaborador">
                                    <div className={Styles.dadosRow}>
                                        <div>Nome Completo: </div>
                                        <div className={Styles.capitalize}>{formulario.Nome}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Cpf:</div>
                                        <div>{formulario.Cpf}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Nome da Mãe:</div>
                                        <div className={Styles.capitalize}>{formulario.NomeMae}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Última Filial:</div>
                                        <div>{formulario.UnidadeNegocio?.label}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Último Cargo:</div>
                                        <div>{formulario.UltimoCargo?.label}</div>
                                    </div>
                                    {!!formulario.Email &&
                                        <div className={Styles.dadosRow}>
                                            <div>E-mail:</div>
                                            <div>{formulario.Email}</div>
                                        </div>
                                    }
                                    {!!formulario.Telefone &&
                                        <div className={Styles.dadosRow}>
                                            <div>Celular:</div>
                                            <div>{formulario.Telefone}</div>
                                        </div>
                                    }
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    {PagesContext.tipoSolicitante === 2 &&
                        <>
                            <div className="row mb-4 mt-4">
                                <div className={Styles.dadosBox}>
                                    <div className={Styles.representanteBox}>
                                        <label className={Styles.dadosLabel}
                                            onClick={() => setOpenRepresentante(!openRepresentante)}
                                            aria-controls="representante"
                                            aria-expanded={openRepresentante}
                                        >Dados do Representante Legal</label>
                                    </div>
                                    <Collapse in={openRepresentante} >
                                        <div id="representante">
                                            <div className={Styles.dadosRow}>
                                                <div>Nome Completo: </div>
                                                <div className={Styles.capitalize}>{formulario.NomeRepresentante}</div>
                                            </div>
                                            <div className={Styles.dadosRow}>
                                                <div>Cpf:</div>
                                                <div>{formulario.CpfRepresentante}</div>
                                            </div>
                                            <div className={Styles.dadosRow}>
                                                <div>E-mail:</div>
                                                <div>{formulario.EmailRepresentante}</div>
                                            </div>
                                            <div className={Styles.dadosRow}>
                                                <div>Telefone:</div>
                                                <div>{formulario.TelefoneRepresentante}</div>
                                            </div>
                                            <div className={Styles.dadosRow}>
                                                <div>Documentação: </div>
                                                {!!anexos.length
                                                    ? <div style={{ fontWeight: "bold", color: "green" }}>Anexada</div>
                                                    : <div style={{ fontWeight: "bold", color: "red" }}>Não Anexada</div>
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </>
                    }
                    <div className="row mb-4">
                        <div className={Styles.dadosBox}>
                            <label className={Styles.dadosLabel}
                                onClick={() => setOpenDadosBancarios(!openDadosBancarios)}
                                aria-controls="dadosBancarios"
                                aria-expanded={openDadosBancarios}
                            >Dados para Recebimento do PPR</label>
                            <Collapse in={openDadosBancarios} >
                                <div id="dadosBancarios">
                                    <div className={Styles.dadosRow}>
                                        <div>Banco: </div>
                                        <div>{formulario.Banco?.label}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Tipo de Conta:</div>
                                        <div>{formulario.TipoConta?.label}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Agência:</div>
                                        <div>{formulario.Agencia}{!!formulario.AgenciaDigito ? `-${formulario.AgenciaDigito}` : ""}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Número da Conta:</div>
                                        <div>{formulario.NumeroConta}-{formulario.ContaDigito}</div>
                                    </div>
                                    {formulario.Operacao &&
                                        <div className={Styles.dadosRow}>
                                            <div>Operação:</div>
                                            <div>{formulario.Operacao}</div>
                                        </div>
                                    }
                                    <>
                                        <div className={Styles.dadosRow}>
                                            <div>Tipo de Chave Pix:</div>
                                            <div>{formulario.TipoPix?.label || "---"}</div>
                                        </div>
                                        <div className={Styles.dadosRow}>
                                            <div>Chave Pix:</div>
                                            <div>{formulario.ChavePix || "---"}</div>
                                        </div>
                                    </>
                                    <div className={Styles.dadosRow}>
                                        <div>Meio de Contato:</div>
                                        <div>{formulario.TipoContato === "E" ? "E-mail" : "Telefone"}</div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button className={`btn ${Styles.botaoReverDados}`} onClick={voltarForm}><FaLessThan /> Rever dados</button>
                    <button className={`btn ${Styles.botaoEnviar}`} onClick={avancaForm}>Enviar solicitação <FaGreaterThan /></button>
                </div>
            </div>

            <LoadingModal isLoading={criandoSolicitacao} />
        </>
    )
}

export default Confirmacao
