import React, { useEffect, useState } from 'react'
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { geraNotificacao } from "../../utils/funcoesNotificacao";
import api from '../../services/api';
import { IDadosIdentificacao } from '../../interfaces/IPPRInativoSolicitacao';
import Collapse from 'react-bootstrap/Collapse'
import LoadingModal from '../../components/LoadingModal';
import { GrDocumentPdf } from "react-icons/gr";
import { Button } from "@material-ui/core";
import { AESEncryptMessage } from '../../utils/encrypt';
import { Else, If, Then } from "react-if";

interface ConfirmacaoProps {
    formulario: IDadosIdentificacao | null;
}
interface IInformesRendimentosDisponiveis {
    CodigoEmpresa: number;
    Matricula: number;
    AnoReferencia: number;
}

const Confirmacao: React.FC<ConfirmacaoProps> = ({ formulario }) => {
    const [openExColaborador, setOpenExColaborador] = useState(true);

    const [carregandoInformes, setCarregandoInformes] = useState(false);

    const [informes, setInformes] = useState<IInformesRendimentosDisponiveis[]>([]);
    const [dadosConfirmacao, setDadosConfirmacao] = useState<IDadosIdentificacao>();

    useEffect(() => {
        if (formulario) {

            const cpfBusca = AESEncryptMessage(formulario.Cpf.replace(/\D/g, ''));

            const data = {
                CPF: cpfBusca
            }

            setDadosConfirmacao(formulario);

            setCarregandoInformes(true);   
            api.get("api/Rendimento/ObterInformesRendimentos?CPF="+data.CPF)
            .then((res: any) => {
                if (res === '' || res === undefined || res.data === undefined || res.data === '') {
                    console.log(res)
                    geraNotificacao({
                        tipo: "customizado",
                        mensagem: "Não foi encontrado nenhum informe no momento. Por favor, avise-nos para avaliar este problema!",
                        severity: "error"
                    });
                } else if (res?.data.length > 0) {
                    setInformes(res.data)
                }
            })
            .catch((erro: any) => {
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: "Ocorreu um erro ao consultar os arquivos de informe de rendimentos. Por favor, avise-nos para avaliar este problema!"+erro,
                    severity: "error"
                });
            })
            .finally(() => setCarregandoInformes(false)); 
        }
    }, [formulario])

    const fetchAndDownloadPDF = async (Ano: number, Empresa: number, Matricula: number) => {
        setCarregandoInformes(true); 
        
        const cpf = dadosConfirmacao?.Cpf == undefined ? "" : dadosConfirmacao?.Cpf;
        const cpfEncrypt = AESEncryptMessage(cpf.replace(/\D/g, ''));
       
        const postDocumento = {
            AnoBase: Ano,
            Empresa: Empresa,
            Matricula: Matricula,
            CPF: cpfEncrypt,
            UltimaFilial: dadosConfirmacao?.UnidadeNegocio?.value,
            UltimoCargo: dadosConfirmacao?.UltimoCargo?.value,
            NomeMae: dadosConfirmacao?.NomeMae,
            Nome: dadosConfirmacao?.Nome
        }

        try {
            const response = await api.post(process.env.REACT_APP_URL+"api/Rendimento/ObterInformeRendimentoDocumento", postDocumento);
            const base64PDF = response.data;
            // Remover o prefixo "data:application/pdf;base64," se existir
            const base64Content = base64PDF.split(',')[1] || base64PDF;
            // Decodificar o Base64 para um Blob
            const byteCharacters = atob(base64Content);
            const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            // Criar uma URL para o Blob
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            geraNotificacao({
                tipo: "customizado",
                mensagem: "Ocorreu um erro ao abrir o PDF!",
                severity: "error"
            });
        } finally {
            setCarregandoInformes(false);
        }
    };
    
    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Informe de rendimentos disponíveis</h2>

                    <div className="row mb-4 mt-4">
                        <div className={Styles.dadosBox}>
                            <label className={Styles.dadosLabel}
                                onClick={() => setOpenExColaborador(!openExColaborador)}
                                aria-controls="excolaborador"
                                aria-expanded={openExColaborador}
                            >Dados do Ex-colaborador</label>
                            <Collapse in={openExColaborador} >
                                <div id="excolaborador">
                                    <div className={Styles.dadosRow}>
                                        <div>Nome Completo: </div>
                                        <div className={Styles.capitalize}>{formulario?.Nome}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>CPF:</div>
                                        <div>{formulario?.Cpf}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>E-mail:</div>
                                        <div>{formulario?.Email}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Celular:</div>
                                        <div>{formulario?.Telefone}</div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>

                    <If condition={informes !== undefined && informes.length > 0}>
                        <Then>
                            <div className="row mb-4 mt-4">
                                <div className="col-md-12">
                                    <p>Documentos disponíveis</p>    
                                    <div className="d-flex">
                                        <ul style={{padding: 0}}>
                                            {informes.map(informe => (
                                                <li style={{all: 'unset'}}>
                                                    <div key={informe.AnoReferencia} className="d-flex flex-column mb-3">
                                                        <Button style={{ background: "#e9fafc" }} onClick={() => fetchAndDownloadPDF(informe.AnoReferencia, 
                                                                                                                                     informe.CodigoEmpresa,
                                                                                                                                     informe.Matricula)}>
                                                            <GrDocumentPdf /> 
                                                            <span style={{marginLeft: 8}}>Informe-Rendimento-{informe.AnoReferencia}</span>
                                                        </Button>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Then>
                        <Else>
                            <div className='row' style={{marginBottom: '1.5rem'}}>
                                <span className='col'>Não foi encontrado arquivos de informes de rendimentos disponíveis no momento!</span>
                            </div>
                        </Else>
                    </If>                               
                </div>
            </div>

            <LoadingModal isLoading={carregandoInformes} />
        </>
    )
}

export default Confirmacao